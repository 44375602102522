import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { apiRequest } from "../services/baseApi";
import { practiceSelected } from "../services/patientapi";
import { Annotation, Bundle, BundleEntry, Task } from "../types/fhir";
import { findTaskById, findTasks, updateTask } from "../services/taskApi";

export class UITask {
  id: string;
  description: string;
  status: string;
  fullStatus: string;
  dueDate: string;
  code: string;
  statusGroup: "TO-DO" | "WAITING" | "IN-PROGRESS" | "DONE";
  priority: string;
  priorityBackgroundColor: string;
  priorityTextColor: string;
  businessStatus: string;
  focus: string;
  forWhom: string;
  statusReason: string;
  notes: Annotation[];
  owner: string;
  lastModified: string;
  authoredOn: string;

  constructor(task: Task) {
    this.id = task.id;
    this.owner = task.owner?.display || "";
    this.code = task.code?.coding?.[0].display || task.code?.text || "";
    this.priority = task.priority || "";
    this.statusReason = task.statusReason?.coding?.[0]?.display || "";
    this.forWhom = task.for?.display || "";
    this.lastModified = task.lastModified || "";
    this.authoredOn = task.authoredOn || "";
    switch (this.priority) {
      case "stat":
      case "urgent":
      case "high":
        this.priorityBackgroundColor = "bg-red-100 dark:bg-red-800";
        this.priorityTextColor = "text-red-700 dark:text-red-100";
        break;
      case "asap":
      case "medium":
        this.priorityBackgroundColor = "bg-orange-100 dark:bg-orange-700";
        this.priorityTextColor = "text-orange-700 dark:text-orange-100";
        break;
      default:
        this.priorityBackgroundColor = "bg-green-100 dark:bg-green-700";
        this.priorityTextColor = "text-green-700 dark:text-green-100";
    }
    this.description = task.description || "";
    this.status = task.status;
    this.notes = task.note || [];
    this.focus = task.focus?.display || "";
    this.businessStatus = this.fullStatus = "";
    if (task.businessStatus && task.businessStatus.text) {
      this.fullStatus = `${task.status} / ${task.businessStatus.text}`;
      this.businessStatus = task.businessStatus.text;
    }
    this.dueDate = "";
    if (
      task.restriction &&
      task.restriction.period &&
      task.restriction.period.end
    ) {
      this.dueDate = new Date(task.restriction.period.end).toDateString();
    }
    this.statusGroup = "TO-DO";
    switch (task.status) {
      case "draft":
      case "requested":
      case "accepted":
        this.statusGroup = "TO-DO";
        break;
      case "rejected":
      case "failed":
      case "completed":
      case "cancelled":
      case "entered-in-error":
        this.statusGroup = "DONE";
        break;
      case "received":
      case "on-hold":
        this.statusGroup = "WAITING";
        break;
      default:
        this.statusGroup = "IN-PROGRESS";
        break;
    }
  }
}

export function useTask(
  taskId: string | undefined,
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void
) {
  const authContext = useMsal();
  const [getTask, setTask] = useState<UITask>();
  if (!taskId) {
    throw new Error("Task ID not specified");
  }

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findTaskById(accessToken, taskId!).then((task: Task) => {
          setLoading(false);
          setTask(new UITask(task));
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {});
  }, [authContext, setTask, setLoading, taskId]);
  return getTask;
}

export function useTasksForPatient(
  patientId: string | undefined,
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void
) {
  const authContext = useMsal();
  const [getTasks, setTasks] = useState([] as UITask[]);

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findTasks(accessToken).then((taskBundle: Bundle) => {
          setLoading(false);
          const tasks: UITask[] = taskBundle.entry
            .filter((entry: BundleEntry) => {
              let task = entry.resource as Task;
              return task.for?.reference === `Patient/${patientId}`;
            })
            .map((entry) => {
              let task = entry.resource as Task;
              return new UITask(task);
            });
          setTasks(tasks);
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {});
  }, [authContext, setTasks, setLoading, patientId]);
  return getTasks;
}

export function useTasks(
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void
) {
  const authContext = useMsal();
  const [getTasks, setTasks] = useState([] as UITask[]);

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findTasks(accessToken).then((taskBundle: Bundle) => {
          setLoading(false);
          const tasks: UITask[] = (taskBundle.entry || []).map((entry) => {
            let task = entry.resource as Task;
            return new UITask(task);
          });
          setTasks(tasks);
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {});
  }, [authContext, setTasks, setLoading]);
  return getTasks;
}

export function useTaskStatus(
  taskId: string,
  taskStatus: string
): [string, React.Dispatch<React.SetStateAction<string>>] {
  const authContext = useMsal();
  const [status, setStatus] = useState(taskStatus);
  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        if (taskId) {
          updateTask(accessToken, taskId, status).then(() => {});
        }
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {});
  }, [authContext, status, taskId]);

  return [status, setStatus];
}
