import { UserIcon } from "@heroicons/react/24/outline";
import React, { MouseEvent, useState } from "react";
import { apiRequest } from "../../services/baseApi";
import { appendNote } from "../../services/taskApi";
import { Task } from "../../types/fhir";
import { practiceSelected } from "../../services/patientapi";
import { useMsal } from "@azure/msal-react";
import { UITask } from "../../hooks/taskHooks";
import Timestamp from "../widgets/Timestamp";
import BasicButton from "../widgets/BasicButton";

// TODO: we shouldn't take a task here, since annotations can be added to other
// things
export default function NoteWidget(props: {
  task: UITask;
  setLastModified: (value: ((prevState: string) => string) | string) => void;
}) {
  const task = props.task;
  const [note, setNote] = useState("");
  const [noteText, setNoteText] = useState("");
  const authContext = useMsal();
  const [notes, setNotes] = useState(task.notes);

  function addNote(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    apiRequest({
      whenAcquired: (accessToken: string) => {
        if (note && note.length) {
          appendNote(accessToken, task.id, note).then((result: Task) => {
            setNotes(result.note || []);
            props.setLastModified(task.lastModified || "");
            setNoteText("");
          });
        }
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {
      setNote("");
      setNoteText("");
    });
  }

  return (
    <>
      <div className="mt-10 ml-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="col-span-full">
          <label
            htmlFor="about"
            className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-50"
          >
            Add Note
          </label>
          <div className="mt-2">
            <textarea
              onChange={(e) => {
                setNoteText(e.target.value);
              }}
              onBlur={(e) => setNote(e.target.value)}
              id="about"
              name="about"
              rows={3}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={noteText}
            />
          </div>
          <div className="mt-6 flex items-center  gap-x-6">
            <BasicButton onClick={addNote}>Add</BasicButton>
          </div>
        </div>

        <div className="col-span-full">
          <ul className="divide-y divide-gray-100">
            {notes
              .sort((a, b) => (b.time || "").localeCompare(a.time || ""))
              .map((annotation) => (
                <li
                  key={
                    (annotation.authorString || "") +
                    annotation.time +
                    annotation.text
                  }
                  className="flex justify-between gap-x-6 py-5"
                >
                  <div className="flex min-w-0 gap-x-4">
                    <UserIcon
                      className="h-12 w-12 flex-none rounded-full bg-gray-50"
                      aria-hidden="true"
                    />

                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-50">
                        {annotation.authorString}
                      </p>
                      <p className={"dark:text-gray-50"}>{annotation.text}</p>
                    </div>
                  </div>
                  <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p className="text-sm leading-6 text-gray-900 dark:text-gray-50">
                      <Timestamp time={annotation.time} />
                    </p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
}
