import patientClient from "../rc360-client";

export async function findTasks(token: string) {
  const response = await patientClient.get(`/fhir/r4/tasks`, {
    headers: {
      Authorization: `Bearer ${token.trim()}`,
    },
  });
  return response.data;
}

export async function updateTask(
  token: string,
  taskId: string,
  status: string
) {
  const response = await patientClient.patch(
    `/fhir/r4/tasks/${taskId}`,
    { status: status },
    {
      headers: {
        Authorization: `Bearer ${token.trim()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response.data;
}

export async function appendNote(token: string, taskId: string, note: string) {
  const response = await patientClient.post(
    `/fhir/r4/tasks/${taskId}/notes`,
    { note: note },
    {
      headers: {
        Authorization: `Bearer ${token.trim()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response.data;
}

export async function findTaskById(
  token: string,
  taskId: string
): Promise<any> {
  const response = await patientClient.get(`/fhir/r4/tasks/${taskId}`, {
    headers: {
      Authorization: `Bearer ${token.trim()}`,
    },
  });
  return response.data;
}
