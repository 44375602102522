import React, { ReactNode } from "react";

export default function BasicButton(props: {
  children: ReactNode;
  onClick?: any;
}) {
  return (
    <button
      onClick={props.onClick ? props.onClick : undefined}
      type={"button"}
      className={
        "ml-3 inline-flex items-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
      }
    >
      {props.children}
    </button>
  );
}
