import React, { useEffect, useState } from "react";
import Message from "./Message";
import { PatientMessage } from "../../types/message";

interface MessagesProps {
  patientId: string;
}

export default function Messages({ patientId }: MessagesProps) {
  const messageList = [
    {
      response: false,
      timestamp: new Date(2024, 10, 1, 11, 1, 13),
      senderName: "Patient 1",
      senderReference: "Patient/123",
      text: "Hello",
    },
    {
      response: true,
      timestamp: new Date(2024, 10, 1, 11, 2, 46),
      senderName: "Andrew 1",
      senderReference: "Practitioner/456",
      text: "Hello, How can I help you today?",
    },
    {
      response: false,
      timestamp: new Date(2024, 10, 1, 11, 3, 23),
      senderName: "Patient 1",
      senderReference: "Patient/123",
      text: "I have back pain",
    },
    {
      response: false,
      timestamp: new Date(2024, 10, 1, 11, 4, 23),
      senderName: "Andrew 1",
      senderReference: "Practitioner/456",
      text: "Sir, this is a Wendy's",
    },
    {
      response: false,
      timestamp: new Date(2024, 10, 1, 11, 5, 22),
      senderName: "Patient 1",
      senderReference: "Patient/123",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
  ];
  const [messages, setMessages] = useState<PatientMessage[]>(messageList);
  const [message, setMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [messages]);

  function detectEnter(event: any) {
    if (event.code === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const newItem: PatientMessage = {
        response: false,
        timestamp: new Date(),
        senderName: "Andrew 1",
        senderReference: "Practitioner/456",
        text: event.target.value,
      };
      setMessages(messages.concat([newItem]));
      setMessage("");
    }
  }

  return (
    <div className="ml-8 mr-8 mt-10">
      <div>
        {messages.map((message, index) => (
          <Message key={index} message={message} />
        ))}
      </div>
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
        <div className="col-span-full">
          <div className="mt-2">
            <textarea
              name="sendMessage"
              placeholder="Type here to send a message"
              rows={4}
              onKeyUp={detectEnter}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
